<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'ข้อมูลเกี่ยวกับ มอก.';
			const content = `
				<p>มาตรฐานผลิตภัณฑ์อุตสาหกรรม หรือ มอก. (Thai Industrial Standard) คือ ข้อกำหนดทางวิชาการที่ สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม (สมอ.) กระทรวงอุตสาหกรรม ได้มีการกำหนดมาตรฐานยางรถยนต์ 4 มาตรฐาน โดยอ้างอิงจากมาตรฐานสากล หรือข้อกำหนดทางเทคนิคยานยนต์ของสหประชาชาติ (UN Regulation) คือ</p>

				<ul>
					<li>มาตรฐานผลิตภัณฑ์อุตสาหกรรม (มอก.)2718 &ndash; 2560 ยางรถยนต์แบบสูบลมสำหรับรถยนต์และส่วนพ่วง</li>
					<li>มาตรฐานผลิตภัณฑ์อุตสาหกรรม (มอก.)2719 &ndash; 2560 ยางรถยนต์แบบสูบลมสำหรับรถยนต์เชิงพาณิชย์และส่วนพ่วง</li>
					<li>มาตรฐานผลิตภัณฑ์อุตสาหกรรม (มอก.)2720 &ndash; 2560 ยางรถยนต์แบบสูบลมสำหรับรถยนต์เชิงพาณิชย์และส่วนพ่วง</li>
					<li>มาตรฐานผลิตภัณฑ์อุตสาหกรรม (มอก.)2721 &ndash; 2560 เสียงจากยางรถยนต์ที่สัมผัสผิวถนน การยึดเกาะบนพื้นถนนเปียก และความต้านทานการหมุน</li>
				</ul>

				<p><strong>กฎหมายและข้อบังคับเกี่ยวกับผลิตภัณฑ์ยางรถยนต์</strong></p>

				<p>เหตุผลในการประกาศใช้พระราชกฤษฎีกา คือ โดยที่มีความจำเป็นต้องควบคุม ผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน เพื่อให้ยางล้อแบบสูบลมสำหรับรถยนต์และส่วนพ่วง และรถยนต์เชิงพาณิชย์และ ส่วนพ่วงมีมาตรฐานด้านความปลอดภัยที่สอดคล้องกับมาตรฐานสากล เพื่อป้องกันความเสียหายอันอาจจะเกิดแก่ประชาชน</p>

				<p><strong>ตัวอย่าง สัญลักษณ์ มอก. ยางรถยนต์</strong></p>

				<img src="` + require('@/assets/content-tsi.jpg') +`" alt="ข้อมูลเกี่ยวกับ มอก." title="ข้อมูลเกี่ยวกับ มอก." />
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'ข้อมูลเกี่ยวกับ มอก. | YELLOWTiRE';
		const desc = 'มาตรฐานผลิตภัณฑ์อุตสาหกรรม หรือ มอก. (Thai Industrial Standard) คือ ข้อกำหนดทางวิชาการที่ สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม (สมอ.) กระทรวงอุตสาหกรรม  ได้มีการกำหนดมาตรฐานยางรถยนต์ 4 มาตรฐาน โดยอ้างอิงจากมาตรฐานสากล หรือข้อกำหนดทางเทคนิคยานยนต์ของสหประชาชาติ (UN Regulation)';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
    }
}
</script>